/* eslint-disable no-unused-vars */
import Styles from './app.module.scss';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './private';
import { PublicRoute } from './public';

import { Home } from '../home';
import { Login } from '../login';

function App() {
  return (
    <div>
      <Layout>
        {/* <Routes><Route path="/" element={<PrivateRoute component={Home} />} /><Route path="/login" element={<PublicRoute restricted={true} component={Login} />} /></Routes> */}
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Layout>
    </div>
  );
}

const Layout = ({ children }) => (
  <div className={Styles.mainContainer}>
    <div className={Styles.contentContainer}>{children}</div>
  </div>
);

export { App };
